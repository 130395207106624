import { ReadFailure } from "./ReadFailure";
import { UnknownProblem } from "./UnknownProblem";

export function BackendError({ detail }: { detail: unknown }) {
  if (typeof detail !== "object" || !detail) {
    return <UnknownProblem />;
  }
  if ("status" in detail && typeof detail.status === "number") {
    switch (detail.status) {
      case 404:
        return <ReadFailure problem={{ type: "ksoc:read-failure" }} />;
    }
  }
  return <UnknownProblem />;
}
