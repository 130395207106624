import { Button } from "@ksoc-private/ui-core";
import { useRevalidator } from "@remix-run/react";
import type { PropsWithChildren, ReactNode } from "react";

type ErrorContainerProps = PropsWithChildren<{
  title?: string | null;
  image?: ReactNode;
}>;

export function ErrorContainer({
  image,
  title,
  children,
}: ErrorContainerProps) {
  const revalidator = useRevalidator();
  return (
    <section className="tw-py-8 tw-mx-auto tw-max-w-md tw-flex tw-flex-col tw-items-center tw-text-center">
      {image}
      {title && (
        <h2 className="tw-text-3xl tw-mb-2 tw-font-medium tw-text-pretty">
          {title}
        </h2>
      )}
      {children}
      <Button
        size="lg"
        colorScheme="indigo"
        className="tw-mt-6"
        isLoading={revalidator.state !== "idle"}
        disabled={revalidator.state !== "idle"}
        onClick={() => {
          if (revalidator.state === "idle") {
            revalidator.revalidate();
          }
        }}
      >
        Refresh page
      </Button>
    </section>
  );
}
