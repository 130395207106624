import { ErrorContainer } from "./ErrorContainer";
import { ProblemDetail } from "./ProblemDetail";

export function WriteFailure({ problem }: { problem: WriteFailure }) {
  return (
    <ErrorContainer title={problem.title ?? "Unable to save data"}>
      <ProblemDetail detail={problem?.detail} />
    </ErrorContainer>
  );
}
