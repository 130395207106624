import { type ErrorResponse } from "@remix-run/router";
import { ReadFailure } from "./ReadFailure";
import { RouteNotFound } from "./RouteNotFound";
import { UnknownProblem } from "./UnknownProblem";
import { WriteFailure } from "./WriteFailure";

export function RouteError({ error }: { error: ErrorResponse }) {
  // @ts-expect-error accessing private field to figure out whether it's an error on remix vs a backend api error
  if (error.internal) {
    if (error.status === 404) {
      return <RouteNotFound />;
    }
    return (
      <UnknownProblem error={error} problem={{ detail: error.data.message }} />
    );
  }

  const problem: Problem = error.data ?? { type: "about:blank" };
  switch (problem.type) {
    case "ksoc:read-failure":
      return <ReadFailure problem={problem} />;
    case "ksoc:write-failure":
      return <WriteFailure problem={problem} />;
  }

  return <UnknownProblem error={error} problem={problem} />;
}
