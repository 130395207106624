import { nl2br } from "~/util/nl2br";

export function ProblemDetail({ detail }: { detail?: string }) {
  return (
    <>
      <p className="tw-text-md">
        {detail ? (
          nl2br(detail)
        ) : (
          <>
            We couldn't handle your request. This is not expected to happen, so
            congratulations on being a pioneer!
          </>
        )}
      </p>

      <p className="tw-text-md">
        This problem has been reported to our engineering team. If you need
        assistance urgently, please contact our customer support team.
      </p>
    </>
  );
}
